import EventModel from '../../../utils/tracking/models';
import isEmpty from 'lodash/isEmpty';
import mainMenuContext from '../nav_main_menu/nav_main_menu_data';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import throttle from 'lodash/throttle';
import { UserConsumer } from '../context/userContext';
import loadable from '@loadable/component';
import clsx from 'clsx';

const Categories = loadable(() => import('./components/categories'));
const Link = loadable(() => import('react-router-dom/Link'));
const MainMenu = loadable(() => import('../nav_main_menu/nav_main_menu'));
const Notice = loadable(() => import('./components/notice'));

class Masthead extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menu: false,
      active: true,
      isMobile: null,
      hideTitle: false,
      expandUser: false,
      cantClose: true
    };

    // NOTIFICATION KILLSWITCH
    this.showNotice = !isEmpty(props.homepageTopPromo);

    this.lastScroll = 0;
    this.toggleMenu = this.toggleMenu.bind(this);
    this.closeActiveMenu = this.closeActiveMenu.bind(this);
    this.navTimeout = undefined;
    this.mastcontainer = React.createRef();
    this.resize = this.resize.bind(this);
    this.mouseOverUser = this.mouseOverUser.bind(this);
    this.mouseLeavingUser = this.mouseLeavingUser.bind(this);
    this.closeUser = this.closeUser.bind(this);
  }

  componentDidMount() {
    if (typeof window.queryly !== 'undefined') {
      window.queryly.init(
        'c99eafd2993b450c',
        document.querySelectorAll('.masthead, section,footer'),
        { uiversion: 1 }
      );
    }

    this.handleNavScroll = throttle(this.handleNavScroll.bind(this), 100);
    window.addEventListener('scroll', this.handleNavScroll);

    window.addEventListener('resize', this.resize);
    this.resize();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleNavScroll);
  }

  trackLoginClick() {
    const loginClickEvent = new EventModel(
      `login nav link`,
      `login link`,
      `click`,
      `nav-login`,
      `1 | 5`
    );

    loginClickEvent.triggerTrackingEvent(
      'login-nav',
      loginClickEvent.getTrackingObject()
    );
  }

  handleNavScroll() {
    const currentScroll = window.pageYOffset;
    const latestScroll = this.lastScroll;

    if (currentScroll > latestScroll && latestScroll > 1 && this.state.active) {
      this.setState({ active: false });
    } else if (
      (currentScroll < latestScroll || latestScroll < 1) &&
      !this.state.active
    ) {
      this.setState({ active: true });
    }
    this.lastScroll = currentScroll;

    if (this.state.expandUser) {
      this.mouseLeavingUser();
    }
  }

  toggleMenu() {
    this.setState({ menu: !this.state.menu });
  }

  closeActiveMenu() {
    this.navTimeout = setTimeout(() => {
      this.setState({ active: false });
    }, 2000);
  }

  resize() {
    const isMobile = window.innerWidth <= 913;
    if (isMobile !== this.state.isMobile) {
      this.setState({ isMobile });
    }
  }

  mouseOverUser() {
    let { hideTitle } = this.state;
    if (this.state.isMobile) {
      hideTitle = true;
    }

    setTimeout(() => {
      this.setState({
        cantClose: false
      });
    }, 500);

    this.setState({
      expandUser: true,
      hideTitle
    });
  }

  mouseLeavingUser() {
    let { hideTitle } = this.state;
    if (this.state.isMobile || hideTitle === true) {
      hideTitle = false;
    }

    this.setState({
      expandUser: false,
      hideTitle
    });
  }

  closeUser() {
    if (!this.state.cantClose && this.state.isMobile && this.state.expandUser) {
      this.setState({
        hideTitle: false,
        expandUser: false,
        cantClose: true
      });
    } else {
      this.mouseOverUser();
    }
  }

  render() {
    const {
      topics,
      sideNavTopics,
      channels,
      trackClick,
      config,
      activeCategory,
      displayOptions
    } = this.props;
    const { menu, active } = this.state;

    if (!displayOptions.show) return false;

    if (displayOptions.logoOnly && !displayOptions.forceCenter) {
      return (
        <React.Fragment>
          <div
            ref={mastcontainer => {
              this.mastcontainer = mastcontainer;
            }}
            className="masthead"
          >
            <header className="masthead__header">
              <Link to="/" className="masthead__title">
                <h2>Fast Company</h2>
              </Link>
            </header>
          </div>
        </React.Fragment>
      );
    }
    if (displayOptions.logoOnly && displayOptions.forceCenter) {
      return (
        <React.Fragment>
          <div
            ref={mastcontainer => {
              this.mastcontainer = mastcontainer;
            }}
            className="masthead"
          >
            <header className="masthead__header mhCenter">
              <Link to="/" className="masthead__title mhCenter">
                <h2>Fast Company</h2>
              </Link>
            </header>
          </div>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        {this.showNotice && (
          <Notice homepageTopPromo={this.props.homepageTopPromo} />
        )}
        <div
          ref={mastcontainer => {
            this.mastcontainer = mastcontainer;
          }}
          className="masthead"
          onMouseEnter={() => {
            clearTimeout(this.navTimeout);
            this.setState({ active: true });
          }}
          onMouseLeave={this.closeActiveMenu}
        >
          <header className="masthead__header">
            <div className="masthead_menu_container">
              <button
                onClick={this.toggleMenu}
                className="masthead__menu"
                aria-label="dropdown menu"
              />
              <UserConsumer>
                {({ showScreen, isLoggedIn }) => {
                  if (isLoggedIn) return null;

                  return (
                    <div
                      className="masthead__user-login"
                      onClick={e => {
                        e.preventDefault();
                        showScreen({ screen: 'login', mode: 'modal' });
                      }}
                    >
                      <span className="masthead__user-label">Login</span>
                    </div>
                  );
                }}
              </UserConsumer>
            </div>
            <div
              className={clsx('masthead__title', {
                'masthead__title--hidden': this.state.hideTitle
              })}
            >
              <a href="/" alt="Fast Company">
                <div className="masthead__title__text">Fast Company</div>
              </a>
            </div>
            <div className="masthead__interactive">
              <UserConsumer>
                {({
                  isLoggedIn,
                  logoutUser,
                  isUserValid,
                  hasMagazineAccess
                }) => {
                  const isSubscribed = isUserValid && hasMagazineAccess;
                  let emag = '';
                  if (isSubscribed) {
                    emag = (
                      <li className="masthead__user-item">
                        <a
                          target="_blank"
                          className="masthead__user-button"
                          rel="noopener noreferrer"
                          href="https://digital.fastcompany.com/account/VipLogin/F0C157ED-4341-41CE-8F27-DBB558CB6D25"
                        >
                          <p>Digital Magazine</p>
                        </a>
                      </li>
                    );
                  }
                  if (isLoggedIn) {
                    return (
                      <div
                        className={`masthead__user ${
                          this.state.expandUser ? 'masthead__user--hover' : ''
                        }`}
                        onMouseEnter={this.mouseOverUser}
                        onMouseLeave={this.mouseLeavingUser}
                      >
                        <span
                          className={`masthead__user-label masthead__user-logo ${
                            isSubscribed ? 'subscriber' : ''
                          }`}
                          onClick={this.closeUser}
                        >
                          <img
                            alt="user account icon"
                            src="https://assets.fastcompany.com/image/upload/v1683672498/fcweb/purple-account-icon.svg"
                            width="18"
                            height="18"
                          />
                        </span>
                        <ul
                          className={`masthead__user-list ${
                            isSubscribed ? 'subscriber' : ''
                          }`}
                        >
                          <li className="masthead__user-item">
                            <a
                              className="masthead__user-button"
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={e => {
                                e.preventDefault();
                                logoutUser();
                                this.mouseLeavingUser();
                              }}
                            >
                              <p className="logoutLink">Logout</p>
                            </a>
                          </li>
                          {emag}
                          <li className="masthead__user-item">
                            <a
                              className="masthead__user-button"
                              rel="noopener noreferrer"
                              href="/myaccount"
                            >
                              <p>Account</p>
                            </a>
                          </li>
                        </ul>
                      </div>
                    );
                  }

                  if (displayOptions.hideSubscribeBtn) return null;

                  return (
                    <a
                      href="/subscribe?utm_source=topnav&utm_medium=button&utm_campaign=sticky"
                      className="masthead__btn-subscribe"
                    >
                      SUBSCRIBE
                    </a>
                  );
                }}
              </UserConsumer>
              <button className="masthead__search" aria-label="site search">
                <label htmlFor="queryly_toggle">
                  <img
                    src="https://assets.fastcompany.com/image/upload/v1694716383/site/icons/search.svg"
                    alt="search"
                    width="18"
                    height="18"
                  />
                </label>
              </button>
            </div>
          </header>
          {displayOptions.hideCategories ? null : (
            <Categories
              topics={topics}
              channels={channels}
              active={active}
              activeCategory={activeCategory}
              trackClick={trackClick}
            />
          )}
          <MainMenu
            closeMenu={this.toggleMenu}
            mainMenuVisible={menu}
            config={config}
            topics={sideNavTopics}
            {...mainMenuContext}
          />
        </div>
      </React.Fragment>
    );
  }
}

Masthead.propTypes = {
  config: PropTypes.shape({}).isRequired,
  topics: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  sideNavTopics: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  channels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  trackClick: PropTypes.func,
  activeCategory: PropTypes.string.isRequired,
  homepageTopPromo: PropTypes.shape({}),
  displayOptions: PropTypes.shape({
    show: PropTypes.bool,
    logoOnly: PropTypes.bool,
    hideSubscribeBtn: PropTypes.bool,
    hideCategories: PropTypes.bool
  })
};

Masthead.defaultProps = {
  trackClick: () => {},
  homepageTopPromo: {},
  displayOptions: {
    show: true,
    logoOnly: false,
    hideSubscribeBtn: false,
    hideCategories: false
  }
};

export default Masthead;
