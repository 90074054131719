const data = {
  currentIssueCover:
    'https://images.fastcompany.net/image/upload/v1721746279/fcweb/vupy1mullsyqx45px6vx.webp',
  magazineLink: 'https://www.fastcompany.com/magazine/',
  subscribeLink:
    'https://www.fastcompany.com/subscribe?utm_source=topnav&utm_medium=button&utm_campaign=sticky',
  cosites: [
    {
      siteTitle: 'FastCoDesign',
      siteLogo:
        '//a.fastcompany.net/image/upload/v1488299643/fc/CO.DESIGN_BURGER_LOCKUP.svg',
      siteURL: '//www.fastcodesign.com'
    },
    {
      siteTitle: 'FastCoStudios',
      siteLogo:
        '//a.fastcompany.net/image/upload/v1488300562/fc/video_burger.svg',
      siteURL: '//www.fastcompany.com/videos'
    }
  ],
  social: {
    fastcompany: [
      {
        property: 'twitter',
        url: 'https://twitter.com/fastcompany',
        image:
          'https://images.fastcompany.net/image/upload/v1540911625/fcweb/tw-icon-cccccc-2x_xgkf9v.svg',
        image_menu:
          'https://images.fastcompany.com/image/upload/v1576118408/tw-icon-circle-tw.svg',
        iconSVGBlack:
          'https://assets.fastcompany.com/image/upload/v1695923902/social-share-icon-twitter-in-circle.svg',
        iconSVGWhite:
          'https://assets.fastcompany.com/image/upload/v1695923902/social-share-icon-twitter.svg',
        iconSVGBlack2x:
          'https://assets.fastcompany.com/image/upload/v1696285191/social-share-icon_2x-twitter-in-circle.png',
        iconSVGWhite2x:
          'https://assets.fastcompany.com/image/upload/v1696285228/social-share-icon_2x-twitter.png'
      },
      {
        property: 'linkedin',
        url: 'https://www.linkedin.com/company/fast-company',
        image:
          'https://images.fastcompany.net/image/upload/v1540911626/fcweb/linkedin-icon-cccccc-2x_ceogdj.svg',
        image_menu:
          'https://images.fastcompany.com/image/upload/v1576118408/linkedin-icon-circle-li.svg'
      },
      {
        property: 'facebook',
        url: 'https://www.facebook.com/FastCompany/',
        image:
          'https://images.fastcompany.net/image/upload/v1540911625/fcweb/fb-icon-cccccc-2x_anjwvt.svg',
        image_menu:
          'https://images.fastcompany.com/image/upload/v1576118408/fb-icon-circle-fb.svg'
      },
      {
        property: 'instagram',
        url: 'https://www.instagram.com/fastcompany/',
        image:
          'https://images.fastcompany.net/image/upload/v1540911625/fcweb/insta-icon-cccccc-2x_qsacmj.svg',
        image_menu:
          'https://images.fastcompany.com/image/upload/v1576118408/insta-icon-circle-in.svg'
      },
      {
        property: 'youtube',
        url: 'https://www.youtube.com/user/FastCompany'
      }
    ]
  },
  fastcoworks: [
    {
      title: 'fastco works',
      className: 'main-menu__collections',
      isOpen: false,
      sections: [
        {
          title: 'ADOBE',
          url: 'https://www.fastcompany.com/fcw/adobe'
        },
        {
          title: 'BOSTON SCIENTIFIC',
          url: 'https://www.fastcompany.com/fcw/boston_scientific'
        },
        {
          title: 'CANVA',
          url: 'https://www.fastcompany.com/fcw/canva'
        },
        {
          title: 'CAPITAL ONE',
          url: 'https://www.fastcompany.com/fcw/capital-one'
        },
        {
          title: 'CHASE FOR BUSINESS',
          url: 'https://www.fastcompany.com/fcw/chase-for-business'
        },
        {
          title: 'Deloitte',
          url: 'https://www.fastcompany.com/fcw/deloitte',
          deck: ''
        },
        {
          title: 'EPSILON',
          url: 'https://www.fastcompany.com/fcw/epsilon',
          deck: ''
        },
        {
          title: 'EY',
          url: 'https://www.fastcompany.com/fcw/ey'
        },
        {
          title: 'GS1 US',
          url: 'https://www.fastcompany.com/fcw/gs1-us'
        },
        {
          title: 'HYUNDAI',
          url: 'https://www.fastcompany.com/fcw/hyundai'
        },
        {
          title: 'IBM',
          url: 'https://www.fastcompany.com/fcw/ibm',
          deck: ''
        },
        {
          title: 'INTUIT MAILCHIMP',
          url: 'https://www.fastcompany.com/fcw/intuit-mailchimp',
          deck: ''
        },
        {
          title: 'PFIZER',
          url: 'https://www.fastcompany.com/fcw/pfizer',
          deck: ''
        },
        {
          title: 'SAP',
          url: 'https://www.fastcompany.com/fcw/SAP'
        },
        {
          title: 'STARBUCKS',
          url: 'https://www.fastcompany.com/fcw/starbucks'
        },
        {
          title: 'VERIZON',
          url: 'https://www.fastcompany.com/fcw/verizon'
        },
        {
          title: 'FASTCO WORKS',
          url: 'https://www.fastcompany.com/fcw',
          deck:
            "An award-winning team of journalists, designers, and videographers who tell brand stories through Fast Company's distinctive lens"
        }
      ]
    }
  ]
};

export default data;
